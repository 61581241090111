<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear
							style="width: 180px;">
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="时间段" class="ui-form__item">
						<a-range-picker v-model:value="time" :disabledDate="disabledDate"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
<!--                      <ExportReport type="financeDayRevenueStatementExportStrategy" :searchData="searchData"></ExportReport>-->
            <a-button type="primary" @click="onExport">导出</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>

			</a-form>

      <!--日期展示等-->
      <div class="ui-form__report" style="margin-top: 20px"></div>

      <a-table :columns="columns2" :data-source="list" bordered :pagination="false">
        <template #bodyCell="{ column, text }">
        </template>
      </a-table>
		</a-spin>
    <a-modal v-model:visible="exportVisible" title="导出" @ok="onExportOk" @cancel="onExportCancel">
        <a-form>
          <a-form-item label="文件名称">
            <a-input v-model:value="exportTitle" placeholder="请输入导出文件名称"/>
          </a-form-item>
          <p style="color: #666">文件名称默认为: {{ filename }}</p>
        </a-form>
    </a-modal>
	</div>
</template>

<script>
    import ExportReport from '@/components/exportReport/exportReport.vue';
	  import cTable from '@/components/cTable/index.vue';
    import { InfoCircleOutlined } from '@ant-design/icons-vue';
	import {
		getCinemaAllList
	} from '@/service/modules/cinema.js';
	import {
		getfinanceDayRevenueStatementList
	} from "@/service/modules/report";
  import {
    excelExport,excelImport
  } from 'pikaz-excel-js';

	export default {
		components: {
		    ExportReport,
			  cTable,
        InfoCircleOutlined
		},
		data() {
			return {
				showSendModal: false,
				showRejectModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				cinemaAllList: [],
				searchData: {},
				modelRef: {},
				time: [],
				formState: {},
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 100,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
        // 实际渲染内容 -- start
        columns2: [
          {
            title: '所属影院',
            dataIndex: 'cinemaName',
          },
          {
            title: '项目',
            dataIndex: 'type',
          },
          {
            title: '交易笔数',
            dataIndex: 'num',
          },
          {
            title: '收入金额',
            dataIndex: 'incomeAmount',
          },
          {
            title: '退款金额',
            dataIndex: 'refund',
          },
          {
            title: '交易金额',
            dataIndex: 'transactionAmount',
          },
          {
            title: (
              <a-tooltip title="以0.54%计算">
                微信手续费 <InfoCircleOutlined/>
              </a-tooltip>
            ),
            dataIndex: 'wechatFee',
            printTitle: '微信手续费(以0.54%计算)'
          },
          {
            title: '到账金额',
            dataIndex: 'receivedAmount',
          },
          {
            title: '金穗影佣金',
            dataIndex: 'commission',
          },
          {
            title: '商家实收',
            dataIndex: 'income',
          },
        ],
        // 实际渲染内容 -- end
        exportVisible: false,
        exportTitle: '', // 默认为
			}
		},
		created() {
			this.time = [this.moment().subtract(1, 'day').startOf(), this.moment().subtract(1, 'day').endOf()];
			// this.time = [this.moment('2024,5,22').startOf(), this.moment('2024,5,22').endOf()]; //有影票数据
			// this.time = [this.moment('2024,5,20').startOf(), this.moment('2024,5,20').endOf()]; //有卖品数据
			// this.time = [this.moment('2024,4,12').startOf(), this.moment('2024,4,12').endOf()]; //有平台&影院商品数据
			// this.time = [this.moment('2024,5,11').startOf(), this.moment('2024,5,11').endOf()]; //有平台商品数据, 有退款
			// this.time = [this.moment('2024,4,3').startOf(), this.moment('2024,4,3').endOf()]; //有平台商品数据, 有退款
			this.getAllCinemaList();
		},
    computed: {
      filename() {
        return this.moment().format('YYYY-MM-DD') + '营收汇总表';
      }
    },
		mounted() {
			this.onSearch();
		},
		methods: {
			getfinanceDayRevenueStatementList: getfinanceDayRevenueStatementList,
			reset() {
				this.time = [this.moment().subtract(1, 'day').startOf(), this.moment().subtract(1, 'day').endOf()];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.revenueStartDay = this.time[0].startOf('days').unix();
					this.searchData.revenueEndDay = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getfinanceDayRevenueStatementList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
            const list = [];
            ret.data.list.forEach(item=>{
              // item.cinemaId说明: -1:总计, 0:平台, >0:影院
              // 电影票(影院)
              const ticketItem = {
                cinemaName: item.cinemaName,
                type: '电影票',
                num: item.cinemaFlowNum,
                incomeAmount: item.cinemaTotalAmount, // 收入金额
                refund: item.cinemaRefundTotalAmount >= 0 ? -(item.cinemaRefundTotalAmount) : item.cinemaRefundTotalAmount, //退款金额(后台是返回负数的)
                commission: item.cinemaTotalServiceFeeAmount, // 金穗影佣金
              }
              ticketItem.transactionAmount =  (ticketItem.incomeAmount + ticketItem.refund).toFixed(2); // 交易金额
              ticketItem.wechatFee = (ticketItem.transactionAmount * 0.0054).toFixed(2); //微信手续费
              ticketItem.receivedAmount = (ticketItem.transactionAmount - ticketItem.wechatFee).toFixed(2); //到账金额
              ticketItem.income = ticketItem.receivedAmount - ticketItem.commission; // 收入
              if (item.cinemaId > 0) {
                list.push(ticketItem)
              }
              // 卖品(影院)
              const snackItem = {
                cinemaName: item.cinemaName,
                type: '卖品',
                num: item.snackGoodsFlowNum,
                incomeAmount: item.snackGoodsTotalAmount, // 交易金额
                refund: item.snackGoodsRefundTotalAmount, //退款金额
                commission: item.snackGoodsServiceFeeAmount, // 金穗影佣金
              }
              snackItem.transactionAmount =  snackItem.incomeAmount + snackItem.refund; // 交易金额
              snackItem.wechatFee = (snackItem.transactionAmount * 0.0054).toFixed(2); //微信手续费
              snackItem.receivedAmount = (snackItem.transactionAmount - snackItem.wechatFee).toFixed(2); //到账金额
              snackItem.income = (snackItem.receivedAmount - snackItem.commission).toFixed(2); // 收入
              if (item.cinemaId > 0) {
                list.push(snackItem)
              }
              // 演出(影院+平台)
              const performItem = {
                cinemaName: item.cinemaName,
                type: '演出',
                num: item.performFlowNum,
                incomeAmount: item.performTotalAmount, // 交易金额
                refund: item.performRefundTotalAmount, //退款金额
                commission: item.performTotalServiceFeeAmount, // 金穗影佣金
              }
              performItem.transactionAmount =  performItem.incomeAmount + performItem.refund; // 交易金额
              performItem.wechatFee = (performItem.transactionAmount * 0.0054).toFixed(2); //微信手续费
              performItem.receivedAmount = (performItem.transactionAmount - performItem.wechatFee).toFixed(2); //到账金额
              performItem.income = (performItem.receivedAmount - performItem.commission).toFixed(2); // 收入
              if (item.cinemaId > -1) {
                list.push(performItem)
              }
              // 商品(平台)
              const shopGoodsItem = {
                cinemaName: item.cinemaName,
                type: '商品',
                num: item.shopGoodsFlowNum,
                incomeAmount: item.shopGoodsTotalAmount, // 交易金额
                refund: -item.shopGoodsRefundTotalAmount, //退款金额
                commission: item.shopGoodsTotalServiceFeeAmount, // 金穗影佣金
              }
              shopGoodsItem.transactionAmount =  (shopGoodsItem.incomeAmount + shopGoodsItem.refund).toFixed(2); // 交易金额
              shopGoodsItem.wechatFee = (shopGoodsItem.transactionAmount * 0.0054).toFixed(2); //微信手续费
              shopGoodsItem.receivedAmount = (shopGoodsItem.transactionAmount - shopGoodsItem.wechatFee).toFixed(2); //到账金额
              shopGoodsItem.income = (shopGoodsItem.receivedAmount - shopGoodsItem.commission).toFixed(2); // 收入
              if (item.cinemaId > -1) {
                list.push(shopGoodsItem)
              }
              // 券包(平台)
              const couponPacketItem = {
                cinemaName: item.cinemaName,
                type: '券包',
                num: item.couponPacketFlowNum,
                incomeAmount: item.couponPacketTotalAmount, // 交易金额
                refund: -item.couponPacketRefundTotalAmount, //退款金额
                commission: 0, // 金穗影佣金
              }
              couponPacketItem.transactionAmount =  couponPacketItem.incomeAmount + couponPacketItem.refund; // 交易金额
              couponPacketItem.wechatFee = (couponPacketItem.transactionAmount * 0.0054).toFixed(2); //微信手续费
              couponPacketItem.receivedAmount = (couponPacketItem.transactionAmount - couponPacketItem.wechatFee).toFixed(2); //到账金额
              couponPacketItem.income = (couponPacketItem.receivedAmount - couponPacketItem.commission).toFixed(2); // 收入
              if (!item.cinemaId) {
                list.push(couponPacketItem)
              }
              // 一卡通销售(平台)
              const unionCardItem = {
                cinemaName: item.cinemaName,
                type: '一卡通销售',
                num: item.unionCardFlowNum,
                incomeAmount: item.unionCardTotalAmount, // 交易金额
                refund: -item.unionCardRefundTotalAmount, //退款金额
                commission: 0, // 金穗影佣金
              }
              unionCardItem.transactionAmount =  unionCardItem.incomeAmount + unionCardItem.refund; // 交易金额
              unionCardItem.wechatFee = (unionCardItem.transactionAmount * 0.0054).toFixed(2); //微信手续费
              unionCardItem.receivedAmount = (unionCardItem.transactionAmount - unionCardItem.wechatFee).toFixed(2); //到账金额
              unionCardItem.income = (unionCardItem.receivedAmount - unionCardItem.commission).toFixed(2); // 收入
              if (!item.cinemaId) {
                list.push(unionCardItem)
              }
              // 一卡通充值(平台)
              const unionCardChargeItem = {
                cinemaName: item.cinemaName,
                type: '一卡通充值',
                num: item.unionCardChargeFlowNum,
                incomeAmount: item.unionCardChargeTotalAmount, // 交易金额
                refund: -item.unionCardChargeRefundTotalAmount, //退款金额
                commission: 0, // 金穗影佣金
              }
              unionCardChargeItem.transactionAmount =  unionCardChargeItem.incomeAmount + unionCardChargeItem.refund; // 交易金额
              unionCardChargeItem.wechatFee = (unionCardChargeItem.transactionAmount * 0.0054).toFixed(2); //微信手续费
              unionCardChargeItem.receivedAmount = (unionCardChargeItem.transactionAmount - unionCardChargeItem.wechatFee).toFixed(2); //到账金额
              unionCardChargeItem.income = (unionCardChargeItem.receivedAmount - unionCardChargeItem.commission).toFixed(2); // 收入
              if (!item.cinemaId) {
                list.push(unionCardChargeItem)
              }
              // 特色会员(平台)
              const specialMemberItem = {
                cinemaName: item.cinemaName,
                type: '特色会员销售',
                num: item.specialMemberFlowNum,
                incomeAmount: item.specialMemberTotalAmount, // 交易金额
                refund: -item.specialMemberRefundTotalAmount, //退款金额
                commission: 0, // 金穗影佣金
              }
              specialMemberItem.transactionAmount =  specialMemberItem.incomeAmount + specialMemberItem.refund; // 交易金额
              specialMemberItem.wechatFee = (specialMemberItem.transactionAmount * 0.0054).toFixed(2); //微信手续费
              specialMemberItem.receivedAmount = (specialMemberItem.transactionAmount - specialMemberItem.wechatFee).toFixed(2); //到账金额
              specialMemberItem.income = (specialMemberItem.receivedAmount - specialMemberItem.commission).toFixed(2); // 收入
              if (!item.cinemaId) {
                list.push(specialMemberItem)
              }
              // 单项合计
              const totalItem = {
                cinemaName: item.cinemaName,
                type: '小计',
                num: '',
                incomeAmount: '', // 交易金额
                refund: '', //退款金额 需要看看是否需要加负号
                transactionAmount: '', //交易金额
                receivedAmount: '', // 到账金额
                wechatFee: '', //微信手续费
                commission: '', // 金穗影佣金
                income: '', // 收入
              }
              if (item.cinemaId > 0) {
                totalItem.num = (ticketItem.num + snackItem.num + performItem.num + shopGoodsItem.num);
                totalItem.incomeAmount = (ticketItem.incomeAmount + snackItem.incomeAmount + performItem.incomeAmount + shopGoodsItem.incomeAmount).toFixed(2);
                totalItem.refund = (ticketItem.refund + snackItem.refund + performItem.refund + shopGoodsItem.refund).toFixed(2);
                totalItem.transactionAmount = (+ticketItem.transactionAmount + (+snackItem.transactionAmount) + (+performItem.transactionAmount) + (+shopGoodsItem.transactionAmount)).toFixed(2);
                totalItem.wechatFee = ((+ticketItem.wechatFee) + (+snackItem.wechatFee) + (+performItem.wechatFee) + (+shopGoodsItem.wechatFee)).toFixed(2);
                totalItem.receivedAmount = ((+ticketItem.receivedAmount) + (+snackItem.receivedAmount) + (+performItem.receivedAmount) + (+shopGoodsItem.receivedAmount)).toFixed(2);
                totalItem.commission = (ticketItem.commission + snackItem.commission + performItem.commission + shopGoodsItem.commission).toFixed(2);
                totalItem.income = ((+ticketItem.income) + (+snackItem.income) + (+performItem.income) + (+shopGoodsItem.income)).toFixed(2);
                list.push(totalItem);
              }
              if (item.cinemaId == 0) {
                totalItem.num = (shopGoodsItem.num + performItem.num + couponPacketItem.num + unionCardItem.num + unionCardChargeItem.num + specialMemberItem.num);
                totalItem.incomeAmount = (shopGoodsItem.incomeAmount + performItem.incomeAmount + couponPacketItem.incomeAmount + unionCardItem.incomeAmount + unionCardChargeItem.incomeAmount + specialMemberItem.incomeAmount).toFixed(2);
                totalItem.refund = (shopGoodsItem.refund + performItem.refund + couponPacketItem.refund + unionCardItem.refund + unionCardChargeItem.refund + specialMemberItem.refund).toFixed(2);
                totalItem.transactionAmount = ((+shopGoodsItem.transactionAmount) + (+performItem.transactionAmount) + (+couponPacketItem.transactionAmount) + (+unionCardItem.transactionAmount) + (+unionCardChargeItem.transactionAmount) + (+specialMemberItem.transactionAmount)).toFixed(2);
                totalItem.wechatFee = ((+shopGoodsItem.wechatFee) + (+performItem.wechatFee) + (+couponPacketItem.wechatFee) + (+unionCardItem.wechatFee) + (+unionCardChargeItem.wechatFee) + (+specialMemberItem.wechatFee)).toFixed(2);
                totalItem.receivedAmount = ((+shopGoodsItem.receivedAmount) + (+performItem.receivedAmount) + (+couponPacketItem.receivedAmount) + (+unionCardItem.receivedAmount) + (+unionCardChargeItem.receivedAmount) + (+specialMemberItem.receivedAmount)).toFixed(2);
                totalItem.commission = ((+shopGoodsItem.commission) + (+performItem.commission) + (+couponPacketItem.commission) + (+unionCardItem.commission) + (+unionCardChargeItem.commission) + (+specialMemberItem.commission)).toFixed(2);
                totalItem.income = ((+shopGoodsItem.income) + (+performItem.income) + (+couponPacketItem.income) + (+unionCardItem.income) + (+unionCardChargeItem.income) + (+specialMemberItem.income)).toFixed(2);
                list.push(totalItem);
              }
            })
            // 合计
            const calculateTotals = () => {
              return list.reduce((acc, item) => {
                if (item.type == '小计') {
                  return acc;
                } else {
                  Object.keys(item).forEach(key => {
                    if (key != 'cinemaName' && key != 'type') {
                      if (!acc[key]) {
                        acc[key] = 0;
                      }
                      // 假设这里的值都是可以转换为数字的，如果实际情况不是，需要做更严格的类型判断和转换处理
                      acc[key] += parseFloat(item[key]) || 0;
                    }
                  });
                  return acc;
                }
              }, {});
            };
            if (list.length) {
              const totalItem = {
                cinemaName: '-',
                type: '总计',
                num: 0,
                incomeAmount: 0, // 交易金额
                refund: 0, //退款金额
                transactionAmount: 0, //交易金额
                receivedAmount: 0, // 到账金额
                wechatFee: 0, //微信手续费
                commission: 0, // 金穗影佣金
                income: 0, // 收入
              }
              list.push({
                ...totalItem,
                ...calculateTotals(),
              });
            }
						this.list = list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaList() {
				this.cinemaAllList = [];
				let ret = await getCinemaAllList({});
				if (ret.code === 200) {
					this.cinemaAllList = ret.data;
				}
			},
			disabledDate(current) {
			    return current && current > this.moment().endOf('day');
			},
      onExport() {
        if (this.list.length) {
          this.exportVisible = true;
          this.exportTitle = '';
        } else {
          this.$message.warn('暂无数据');
        }
      },
      onExportCancel() {
        this.exportVisible = false;
        this.exportTitle = '';
      },
      onExportOk() {
        excelExport({
          filename: this.exportTitle || this.filename,
          onError(err) {
            console.log(err)
          },
          sheet: [{
            // 表格标题
            title: `时间: ${this.searchData.revenueStartDay} ~ ${this.searchData.revenueEndDay}`, // 导出日期
            // 表头
            tHeader: (() => {
              return this.columns2.map(item=>{
                return item.printTitle || item.title
              })
            })(),
            // 数据键名
            keys: (() => {
              return this.columns2.map(item=>{
                return item.dataIndex
              })
            })(),
            // 表格数据
            table: this.list,
            sheetName: "营收汇总报表",
          }]
        })
        this.onExportCancel();
      }
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
  .ui-form__report {
      margin-bottom: 20px;
  }
</style>


