<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
            <a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear
                      style="width: 180px;">
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
                {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="时间段" class="ui-form__item">
            <a-range-picker v-model:value="time" :disabledDate="disabledDate"></a-range-picker>
          </a-form-item>

          <a-form-item class="ui-form__item" name="payMessageType" label="支付方式">
            <a-select placeholder="请选择支付方式" v-model:value="formState.payType" allow-clear
                      style="width: 180px;">
              <a-select-option value="wx_lite"> 微信支付 </a-select-option>
              <a-select-option value="card"> 一卡通支付 </a-select-option>
              <a-select-option value="zero"> 0元支付 </a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <ExportReport type="financeDetailListExportStrategy" :searchData="searchData"></ExportReport>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>

      </a-form>

      <!--日期展示等-->
      <div class="ui-form__report" style="margin-top: 20px"></div>

      <a-table :columns="columns" :data-source="list" bordered :pagination="pagination">
        <template #bodyCell="{ column, record, index }">

          <template v-if="column.dataIndex === 'createTime'">
            {{ transDateTime(record.createTime) }}
          </template>

          <template v-if="column.dataIndex === 'payMessageType'">
            <template v-if="record.payMessageType ==  'card'">
              一卡通
            </template>
            <template v-else-if="record.payMessageType ==  'wx_lite'">
              微信支付
            </template>
            <template v-else-if="record.payMessageType ==  'zero'">
              零元支付
            </template>
            <template v-else>
              -
            </template>
          </template>

        </template>
      </a-table>
    </a-spin>

  </div>
</template>

<script>
  import ExportReport from '@/components/exportReport/exportReport.vue';
  import cTable from '@/components/cTable/index.vue';
  import { InfoCircleOutlined } from '@ant-design/icons-vue';
  import {
    getCinemaAllList
  } from '@/service/modules/cinema.js';
  import {
    getfinanceDayRevenueStatementList,
    getFinanceDetail
  } from "@/service/modules/report";

  export default {
    components: {
      ExportReport,
      cTable,
      InfoCircleOutlined
    },
    data() {
      return {
        showSendModal: false,
        showRejectModal: false,
        isEdit: false,
        isSee: false,
        id: 0,
        loading: false,
        businessType: 0, // 1.商品 2.卖品 3.影票 4.演出 5.卡券 6.一卡通 7.特色会员 8.充值订单
        cinemaAllList: [],
        searchData: {},
        modelRef: {},
        time: [],
        formState: {
          businessType: 0,
        },
        list: [],
        columns: [],
        pagination: {
          showSizeChanger: true,
          showQuickJumper: true,
          size: "middle",
          pageSizeOptions: ['10', '20', '50', '100', '500'],
          showTotal: (total) => {
            return "共 " + total + " 条";
          },
          total: 0,
          current: 1,
          pageSize: 10,
          onChange: (page) => {
            this.pagination.current = page;
            this.getData();
          },
          onShowSizeChange: (page, pageSize) => {
            this.pagination.current = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
          }
        },
      }
    },
    created() {
      const initDetailPage = {
        reportFormsFinanceFilm: () => {
          this.formState.businessType = this.businessType = 3;
          this.columns = [
            {
              title: '所属影院',
              dataIndex: 'cinemaName',
            },
            {
              title: '订单号',
              dataIndex: 'businessNo',
            },
            {
              title: '交易时间',
              dataIndex: 'createTime',
            },
            {
              title: '支付方式',
              dataIndex: 'payMessageType',
            },
            {
              title: '影片名称',
              dataIndex: 'goodsTitle',
            },
            {
              title: '票数',
              dataIndex: 'itemNum',
            },
            {
              title: '交易金额',
              dataIndex: 'flowAmount',
            },
            {
              // title: '影票金额',
              title: (
                <a-tooltip title="交易金额 - 售票系统服务费 - 网售服务费 - 小程序服务费">
                  影票金额 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'transactionIncome',
            },
            {
              title: (
                <a-tooltip title="影票金额 * 0.54%">
                  影票手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatTransactionIncome',
            },
            {
              title: (
                <a-tooltip title="售票系统服务费">
                  售票系统服务费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'ticketServiceFee',
            },
            {
              title: (
                <a-tooltip title="计算公式: 售票系统服务费*0.54%">
                  售票系统服务费手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatTicketServiceFee',
            },
            {
              title: (
                <a-tooltip title="网售服务费">
                  网售服务费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'serviceCharge',
            },
            {
              title: (
                <a-tooltip title="网售服务费 * 0.54%">
                  网售服务费手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatServiceCharge',
            },
            {
              title: (
                <a-tooltip title="影院信息中的影票佣金（金穗影收）">
                  小程序服务费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'cinemaFeeAmount',
            },
            {
              title: (
                <a-tooltip title="小程序服务费*0.54%">
                  小程序服务费手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatCinemaFeeAmount',
            },
            {
              title: '银行实收金额',
              // title: (
              //   <a-tooltip title="交易金额*0.54%">
              //     银行实收金额 <InfoCircleOutlined/>
              //   </a-tooltip>
              // ),
              dataIndex: 'income',
            },
            {
              // title: '影院应收',
              title: (
                <a-tooltip title="影票金额 - 影票手续费 + 售票系统服务费 - 售票系统服务费手续费">
                  影院应收 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'otherIncome',
            },
            {
              // title: '金穗影应收',
              title: (
                <a-tooltip title="网售服务费 - 网售服务费手续费 + 小程序服务费 - 小程序服务费手续费">
                  金穗影应收 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'platformIncome',
            },
          ];
        },
        reportFormsFinanceSnack: () => {
          this.formState.businessType = this.businessType = 2;
          this.columns = [
            {
              title: '所属影院',
              dataIndex: 'cinemaName',
            },
            {
              title: '订单号',
              dataIndex: 'businessNo',
            },
            {
              title: '交易时间',
              dataIndex: 'createTime',
            },
            {
              title: '支付方式',
              dataIndex: 'payMessageType',
            },
            {
              title: '卖品名称',
              dataIndex: 'goodsTitle',
            },
            {
              title: '数量',
              dataIndex: 'itemNum',
            },
            {
              title: '交易金额',
              dataIndex: 'flowAmount',
            },
            {
              title: (
                <a-tooltip title="计算公式: 交易金额 - 小程序服务费">
                  卖品金额 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'transactionIncome',
            },
            {
              title: (
                <a-tooltip title="计算公式: 卖品金额*0.54%">
                  卖品手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatTransactionIncome',
            },
            {
              title: (
                <a-tooltip title="按影院信息中的卖品佣金比例，金穗影收">
                  小程序服务费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'cinemaFeeAmount',
            },
            {
              title: (
                <a-tooltip title="计算公式：小程序服务费*0.54%">
                  小程序服务费手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatCinemaFeeAmount',
            },
            {
              title: '银行实收金额',
              // title: (
              //   <a-tooltip title="交易金额*0.54%">
              //     银行实收金额 <InfoCircleOutlined/>
              //   </a-tooltip>
              // ),
              dataIndex:'income',
            },
            {
              title: '影院应收',
              dataIndex: 'otherIncome',
            },
            {
              title: '金穗影应收',
              dataIndex: 'platformIncome',
            },
          ];
        },
        reportFormsFinanceGoods: () => {
          this.formState.businessType = this.businessType = 1;
          this.columns = [
            {
              title: '所属影院',
              dataIndex: 'cinemaName',
            },
            {
              title: '订单号',
              dataIndex: 'businessNo',
            },
            {
              title: '交易时间',
              dataIndex: 'createTime',
            },
            {
              title: '商品名称',
              dataIndex: 'goodsTitle',
            },
            {
              title: '支付方式',
              dataIndex: 'payMessageType',
            },
            {
              title: '数量',
              dataIndex: 'itemNum',
            },
            {
              title: '交易金额',
              dataIndex: 'flowAmount',
            },
            {
              title: (
                <a-tooltip title="计算公式: 交易金额 - 小程序服务费">
                  商品金额 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'cinemaFeeAmount',
            },
            {
              title: (
                <a-tooltip title="计算公式: 商品金额*0.54%">
                  商品手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatTransactionIncome',
            },
            {
              title: (
                <a-tooltip title="按影院信息中的商品佣金比例，金穗影收">
                  小程序服务费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'cinemaFeeAmount',
            },
            {
              title: (
                <a-tooltip title="计算公式：小程序服务费*0.54%">
                  小程序服务费手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatCinemaFeeAmount',
            },
            {
              title: '银行实收金额',
              dataIndex: 'income',
            },
            {
              title: '影院应收',
              dataIndex: 'otherIncome',
            },
            {
              title: '金穗影应收',
              dataIndex: 'platformIncome',
            },
          ];
        },
        reportFormsFinancePerform: () => {
          this.formState.businessType = this.businessType = 4;
          this.columns = [
            {
              title: '所属影院',
              dataIndex: 'cinemaName',
            },
            {
              title: '订单号',
              dataIndex: 'businessNo',
            },
            {
              title: '交易时间',
              dataIndex: 'createTime',
            },
            {
              title: '支付方式',
              dataIndex: 'payMessageType',
            },
            {
              title: '演出名称',
              dataIndex: 'goodsTitle',
            },
            {
              title: '票数',
              dataIndex: 'itemNum',
            },
            {
              title: '交易金额',
              dataIndex: 'flowAmount',
            },
            {
              title: (
                <a-tooltip title="计算公式: 交易金额 - 小程序手续费">
                  演出票金额 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'transactionIncome',
            },
            {
              title: (
                <a-tooltip title="计算公式: 演出票金额*0.54%">
                  演出票手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatTransactionIncome',
            },
            {
              title: (
                <a-tooltip title="按影院信息中的佣金比例，金穗影收">
                  小程序服务费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'cinemaFeeAmount',
            },
            {
              title: (
                <a-tooltip title="计算公式：小程序服务费*0.54%">
                  小程序服务费手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatCinemaFeeAmount',
            },
            {
              title: '银行实收金额',
              dataIndex: 'income',
            },
            {
              title: '影院应收',
              dataIndex: 'otherIncome',
            },
            {
              title: '金穗影应收',
              dataIndex: 'platformIncome',
            },
          ];
        },
        reportFormsFinanceCardSale: () => {
          this.formState.businessType = this.businessType = 6;
          this.columns = [
            {
              title: '所属影院',
              dataIndex: 'cinemaName',
            },
            {
              title: '订单号',
              dataIndex: 'businessNo',
            },
            {
              title: '卡号',
              dataIndex: 'cardNo',
            },
            {
              title: '卡名称',
              dataIndex: 'cardTitle',
            },
            {
              title: '支付方式',
              dataIndex: 'payMessageType',
            },
            {
              title: '开卡金额',
              dataIndex: 'flowAmount',
            },
            {
              title: (
                <a-tooltip title="计算公式: 开卡金额*0.54%">
                  一卡通手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatCinemaFeeAmount',
            },
            {
              title: '银行实收金额',
              dataIndex: 'income',
            },
          ];
        },
        reportFormsFinanceCardRecharge: () => {
          this.formState.businessType = this.businessType = 8;
          this.columns = [
            {
              title: '所属影院',
              dataIndex: 'cinemaName',
            },
            {
              title: '订单号',
              dataIndex: 'businessNo',
            },
            {
              title: '交易时间',
              dataIndex: 'createTime',
            },
            {
              title: '支付方式',
              dataIndex: 'payMessageType',
            },
            {
              title: '卡号',
              dataIndex: 'cardNo',
            },
            {
              title: '充值金额',
              dataIndex: 'flowAmount',
            },
            {
              title: (
                <a-tooltip title="计算公式: 充值金额*0.54%">
                  一卡通手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatFeeAmount',
            },
            {
              title: '银行实收金额',
              dataIndex: 'income',
            },
            {
              title: '赠送金额',
              dataIndex: 'prizePrice',
            },
            {
              title: '赠券名称',
              dataIndex: 'couponTitle',
            },
          ];
        },
        reportFormsFinanceMember: () => {
          this.formState.businessType = this.businessType = 7;
          this.columns = [
            {
              title: '所属影院',
              dataIndex: 'cinemaName',
            },
            {
              title: '订单号',
              dataIndex: 'businessNo',
            },
            {
              title: '交易时间',
              dataIndex: 'createTime',
            },
            {
              title: '支付方式',
              dataIndex: 'payMessageType',
            },
            // {
            //   title: '用户ID',
            //   dataIndex: 'userId',
            // },
            {
              title: '交易金额',
              dataIndex: 'flowAmount',
            },
            {
              title: (
                <a-tooltip title="计算公式: 交易金额*0.54%">
                  特色会员手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatFeeAmount',
            },
            {
              title: '银行实收金额',
              dataIndex: 'income',
            },
          ];
        },
        reportFormsFinanceCoupon: () => {
          this.formState.businessType = this.businessType = 5;
          this.columns = [
            {
              title: '所属影院',
              dataIndex: 'cinemaName',
            },
            {
              title: '订单号',
              dataIndex: 'businessNo',
            },
            {
              title: '交易时间',
              dataIndex: 'createTime',
            },
            {
              title: '支付方式',
              dataIndex: 'payMessageType',
            },
            {
              title: '券包名称',
              dataIndex: 'businessTitle',
            },
            {
              title: '交易金额',
              dataIndex: 'flowAmount',
            },
            {
              title: (
                <a-tooltip title="计算公式: 交易金额*0.54%">
                  券包手续费 <InfoCircleOutlined/>
                </a-tooltip>
              ),
              dataIndex: 'wechatFeeAmount',
            },
            {
              title: '银行实收金额',
              dataIndex: 'income',
            },
          ];
        },
      };
      initDetailPage[this.$route.name] && initDetailPage[this.$route.name]();
      this.time = [this.moment().subtract(1, 'month').startOf(), this.moment().endOf()];
      this.getAllCinemaList();
    },
    mounted() {
      this.onSearch();
    },
    methods: {
      getfinanceDayRevenueStatementList: getfinanceDayRevenueStatementList,
      reset() {
        this.time = [this.moment().subtract(1, 'month').startOf(), this.moment().endOf()];
        this.$refs.form.resetFields();
        this.onSearch();
      },
      onSearch() {
        this.pagination.current = 1;
        this.searchData = JSON.parse(JSON.stringify(this.formState));
        if (this.time && this.time.length) {
          this.searchData.revenueStartDay = this.time[0].startOf('days').unix();
          this.searchData.revenueEndDay = this.time[1].endOf('days').unix();
        }
        this.getData();
      },
      async getData() {
        const toFixed2 = (num)=>{return parseFloat(num.toFixed(2))};
        const setMinus = (num) =>{
          return -num;
        }
        this.loading = true;
        try {
          let ret = await getFinanceDetail({
            page: this.pagination.current,
            pageSize: this.pagination.pageSize,
            ...this.searchData
          })
          this.loading = false;
          if (ret.code === 200) {
            // const list = [];
            try {
              this.list = ret.data.list.map(item=> {
                // item.flowAmount = item.flowAmount;
                // 小程序服务费手续费
                item.wechatCinemaFeeAmount = 0;
                // 影院应收 (默认设置为0)
                item.otherIncome = 0;
                // 金穗影应收 (默认设置为0)
                item.platformIncome = 0;
                // 银行实收金额 (默认设置为0)
                item.income = 0;
                // 商品金额/卖品金额/... (默认设置为0)
                item.transactionIncome = 0;
                // 商品金额/卖品金额/...的微信手续费 (默认设置为0)
                item.wechatTransactionIncome = 0;
                if (item.payMessageType == 'wx_lite') {
                  item.wechatCinemaFeeAmount =  toFixed2(item.cinemaFeeAmount * 0.0054);
                  item.income = toFixed2(item.flowAmount * (1 - 0.0054)); // 银行实收
                }

                if (this.businessType == 1) {
                  // 商品
                  item.goodsTitle = (()=>{
                    let goodsTitle = [];
                    item.extra && item.extra.goodsList && item.extra.goodsList.forEach(subItem=>{
                      if (goodsTitle.indexOf(subItem.goodsTitle) == -1) {
                        goodsTitle.push(subItem.goodsTitle);
                      }
                    })
                    return goodsTitle.join(',') || '-';
                  })();
                  if (item.payMessageType == 'wx_lite') {
                    item.transactionIncome = toFixed2(item.flowAmount - item.cinemaFeeAmount);
                    item.wechatTransactionIncome = toFixed2(item.transactionIncome * 0.0054);
                    // 影院应收
                    item.otherIncome = toFixed2(item.transactionIncome - item.wechatTransactionIncome);
                    // 金穗影应收
                    item.platformIncome = toFixed2(item.cinemaFeeAmount - item.wechatCinemaFeeAmount);
                  } else if (item.payMessageType == 'card') {
                    item.transactionIncome = item.otherIncome = item.flowAmount;
                    item.platformIncome = item.cinemaFeeAmount;
                  }
                }
                if (this.businessType == 2) {
                  // 卖品
                  // 卖品金额 = 交易金额 - 小程序服务费(金穗影收)
                  item.goodsTitle = (()=>{
                    let goodsTitle = [];
                    item.extra.goodsList.forEach(subItem=>{
                      if (goodsTitle.indexOf(subItem.goodsTitle) == -1) {
                        goodsTitle.push(subItem.goodsTitle);
                      }
                    })
                    return goodsTitle.join(',');
                  })();
                  if (item.payMessageType == 'wx_lite') {
                    item.transactionIncome = toFixed2(item.flowAmount - item.cinemaFeeAmount);
                    item.wechatTransactionIncome = toFixed2(item.transactionIncome - 0.0054);
                    // 影院应收
                    item.otherIncome = toFixed2(item.transactionIncome - item.wechatTransactionIncome);
                    // 金穗影应收
                    item.platformIncome = toFixed2(item.cinemaFeeAmount - item.wechatCinemaFeeAmount);
                  } else if (item.payMessageType == 'card') {
                    item.transactionIncome = item.otherIncome = item.flowAmount;
                    item.platformIncome = item.cinemaFeeAmount;
                  }
                }
                if (this.businessType == 3) {
                  let goodsTitle = [];
                  item.serviceCharge = 0; // 网售商服务费
                  item.wechatServiceCharge = 0; // 网售商服务费手续费
                  item.ticketServiceFee = 0; // 售票系统服务费
                  item.wechatTicketServiceFee = 0; // 售票系统服务费手续费
                  item.extra.goodsList && item.extra.goodsList.forEach(subItem=>{
                    if (goodsTitle.indexOf(subItem.goodsTitle) == -1) {
                      goodsTitle.push(subItem.goodsTitle);
                    }
                    subItem._seatInfo = JSON.parse(subItem.seatInfo);
                    item.serviceCharge =+ subItem._seatInfo.serviceCharge;
                    item.ticketServiceFee =+ (subItem._seatInfo.ticketFee + subItem._seatInfo.serviceFee);
                  })
                  item.goodsTitle = goodsTitle.join(',');
                  item.serviceCharge = toFixed2(item.serviceCharge);
                  item.ticketServiceFee = toFixed2(item.ticketServiceFee);
                  item.wechatServiceCharge = toFixed2(item.serviceCharge * 0.0054);
                  item.wechatTicketServiceFee = toFixed2(item.ticketServiceFee * 0.0054);
                  // 影票金额 = 交易金额 - 售票系统服务费 - 小程序服务费(金穗影收)
                  item.transactionIncome = toFixed2(item.flowAmount - item.ticketServiceFee - item.serviceCharge - item.cinemaFeeAmount);
                  item.wechatTransactionIncome = toFixed2(item.transactionIncome * 0.0054);
                  // 影院应收
                  item.otherIncome = toFixed2(item.transactionIncome - item.wechatTransactionIncome + item.ticketServiceFee - item.wechatTicketServiceFee);
                  // 金穗影应收
                  item.platformIncome = toFixed2(item.serviceCharge - item.wechatServiceCharge + item.cinemaFeeAmount - item.wechatCinemaFeeAmount);
                }
                if (this.businessType == 4) {
                  item.goodsTitle = (()=>{
                    let goodsTitle = [];
                    item.extra.goodsList && item.extra.goodsList.forEach(subItem=>{
                      if (goodsTitle.indexOf(subItem.goodsTitle) == -1) {
                        goodsTitle.push(subItem.goodsTitle);
                      }
                    })
                    return goodsTitle.join(',') || '-';
                  })();
                  if (item.payMessageType == 'wx_lite') {
                    item.transactionIncome = toFixed2(item.flowAmount - item.cinemaFeeAmount);
                    item.wechatTransactionIncome = toFixed2(item.transactionIncome * 0.0054);
                    // 影院应收
                    item.otherIncome = toFixed2(item.transactionIncome - item.wechatTransactionIncome);
                    // 金穗影应收
                    item.platformIncome = toFixed2(item.cinemaFeeAmount - item.wechatCinemaFeeAmount);
                  } else if (item.payMessageType == 'card') {
                    item.transactionIncome = item.otherIncome = item.flowAmount;
                    item.platformIncome = item.cinemaFeeAmount;
                  }
                }
                if (this.businessType == 5) {
                  item.businessTitle = item.extra.businessTitle;
                }
                if (this.businessType == 6) {
                  item.cardNo = item.extra.cardNo; // 卡号
                  item.cardTitle = (item.extra && item.extra.cardTitle) || ''; // 卡号
                  item.wechatCinemaFeeAmount =  toFixed2(item.flowAmount * 0.0054);
                }
                if (this.businessType == 7) {
                  item.userId = item.extra.userId; // 用户ID
                }
                if (this.businessType == 8) {
                  item.cardNo = item.extra.cardNo || ''; // 卡号
                  item.prizePrice = item.extra.prizePrice || ''; // 赠送金额
                  item.couponTitle = (()=>{
                    let couponList = [];
                    item.extra.couponList && item.extra.couponList.forEach(subItem=>{
                      if (couponList.indexOf(subItem.title) == -1) {
                        couponList.push(subItem.title);
                      }
                    })
                    return couponList.join(',') || '-';
                  })();
                }
                if (item.flowType == 2) {
                  // 对数字做负数处理
                  item.itemNum = setMinus(item.itemNum);
                  item.flowAmount = setMinus(item.flowAmount);
                  item.transactionIncome = setMinus(item.transactionIncome);
                  item.wechatTransactionIncome = setMinus(item.wechatTransactionIncome);
                  item.ticketServiceFee = setMinus(item.ticketServiceFee);
                  item.wechatTicketServiceFee = setMinus(item.wechatTicketServiceFee);
                  item.serviceCharge = setMinus(item.serviceCharge);
                  item.wechatServiceCharge = setMinus(item.wechatServiceCharge);
                  item.cinemaFeeAmount = setMinus(item.cinemaFeeAmount);
                  item.wechatCinemaFeeAmount = setMinus(item.wechatCinemaFeeAmount);
                  item.income = setMinus(item.income);
                  item.otherIncome = setMinus(item.otherIncome);
                  item.platformIncome = setMinus(item.platformIncome);
                }
                return item;
              });
            } catch (e) {
              console.log(e)
            }
            this.pagination.total = ret.data.totalCount;

          }
        } catch (e) {
          this.loading = false;
        }
      },
      async getAllCinemaList() {
        this.cinemaAllList = [];
        let ret = await getCinemaAllList({});
        if (ret.code === 200) {
          this.cinemaAllList = ret.data;
        }
      },
      disabledDate(current) {
        return current && current > this.moment().endOf('day');
      }
    }
  }
</script>

<style scoped>
    .ui-form__item {
        margin-right: 30px;
    }
    .ui-form__report {
        margin-bottom: 20px;
    }
</style>
